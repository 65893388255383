<template>
  <div class="order-list">
    <div class="o-container">
      <div class="o-hd" v-loading="loading">
        <div class="h-title">
          <li class="t-item" :class="status === 0 ? 't-current' : ''">
            <a @click="ClickStatus(0)">全部订单</a>
          </li>
          <li class="t-item" :class="status === 1 ? 't-current' : ''">
            <a @click="ClickStatus(1)">待付款</a>
          </li>
          <li class="t-item" :class="status === 2 ? 't-current' : ''">
            <a @click="ClickStatus(2)">待收货</a>
          </li>
          <li class="t-item" :class="status === 3 ? 't-current' : ''">
            <a @click="ClickStatus(3)">待评价</a>
          </li>
        </div>
        <div class="h-search">
          <li>
            <el-input
              class="h-input"
              type="text"
              v-model="name"
              placeholder="请输入商品名称"
              size="small"
            />
          </li>
          <li>
            <el-button type="primary" size="small" @click="OrderList()"
              >搜索</el-button
            >
          </li>
        </div>
      </div>
      <div class="o-bd clearfix" v-if="this.list.length">
        <div class="b-content">
          <div class="b-title">
            <li class="b-first">订单详情</li>
            <li class="b-item">收货人</li>
            <li class="b-item">金额</li>
            <li class="b-item">状态</li>
            <li class="b-item">操作</li>
          </div>
          <div class="b-list" v-for="(item, index) in list" :key="index">
            <div class="l-hd">
              <div class="h-content">
                <div class="h-times">{{ item.create_time }}</div>
                <div class="h-order">
                  <span>订单号:</span>
                  &nbsp;
                  <a
                    :href="'/home/orderDetails?id=' + item.id"
                    target="_blank"
                    >{{ item.order_no }}</a
                  >
                </div>
                <div class="store-name">
                  <a href="">{{ item.store_name }}</a>
                </div>
              </div>
            </div>
            <div class="l-main">
              <div class="m-goods">
                <div
                  class="g-list"
                  v-for="(goods, key) in item.orderGoods"
                  :key="key"
                >
                  <div class="g-img"><img :src="goods.img" /></div>
                  <div class="g-name">
                    <a
                      :href="'/store/goods?id=' + goods.goods_id"
                      target="_blank"
                      >{{ goods.goods_name }}</a
                    >
                  </div>
                  <div class="g-num">x{{ goods.goods_num }}</div>
                </div>
                <div class="g-more"></div>
              </div>
              <div class="g-info">
                <div class="m-name m-item">
                  <span>{{ item.accept_name }}</span
                  >&nbsp;
                  <i class="fa fa-user"></i>
                </div>
                <div class="m-money m-item">
                  <div class="m-txt">¥{{ item.real_amount }}</div>
                  <div v-if="item.pay_status == 1">已支付</div>
                  <div v-if="item.pay_status == 0">暂未支付</div>
                </div>
                <div class="m-status m-item">
                  <div v-if="item.status == 1 && item.pay_status == 0">
                    等待付款
                  </div>
                  <div
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 0 &&
                      item.delivery_status == 0
                    "
                  >
                    正在出库
                  </div>
                  <div
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 1 &&
                      item.delivery_status == 2
                    "
                  >
                    在途中
                  </div>
                  <div
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 1 &&
                      item.delivery_status == 3
                    "
                  >
                    待评价
                  </div>
                  <div v-if="item.status == 2 && item.pay_status == 1">
                    待退款
                  </div>
                  <div v-if="item.status == 5 && item.pay_status == 1">
                    已退款
                  </div>
                  <div v-if="item.status == 2 && item.pay_status == 0">
                    已取消
                  </div>
                  <div class="s-txt">
                    <a
                      :href="'/home/orderDetails?id=' + item.id"
                      target="_blank"
                      >订单详情</a
                    >
                  </div>
                </div>
                <div class="m-opt m-item">
                  <div
                    class="o-btn"
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 1 &&
                      item.delivery_status == 3
                    "
                  >
                    <a
                      ><i class="fa fa-bolt fa-lg"></i>&nbsp;<span
                        >再次购买</span
                      ></a
                    >
                  </div>
                  <div
                    class="o-btp"
                    v-if="item.pay_status == 0 && item.status == 1"
                    @click="Topay(item.pay_id)"
                  >
                    <a
                      ><i class="fa fa-bolt fa-lg"></i>&nbsp;<span
                        >立即支付</span
                      ></a
                    >
                  </div>
                  <div
                    class="o-cancel"
                    v-if="item.pay_status == 0 && item.status == 1"
                    @click="
                      reasonsid = item.id;
                      cancelDialogVisible = true;
                    "
                  >
                    <span>取消订单</span>
                  </div>
                  <div
                    class="o-btn"
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 0 &&
                      item.delivery_status == 0
                    "
                    @click="
                      reasonsid = item.id;
                      cancelOrderDialog = true;
                    "
                  >
                    <a><span>取消订单</span></a>
                  </div>
                  <div
                    class="o-btp"
                    v-if="
                      item.status == 1 &&
                      item.pay_status == 1 &&
                      item.distribution_status == 1 &&
                      item.delivery_status == 2
                    "
                    @click="confirmGoods(item.id)"
                  >
                    <a><span>确认收货</span></a>
                  </div>
                  <div
                    class="o-btn"
                    v-if="
                      item.status == 2 || item.status == 3 || item.status == 5
                    "
                  >
                    <a
                      ><i class="fa fa-bolt fa-lg"></i>&nbsp;<span
                        >重新购买</span
                      ></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="o-footer">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            :page-size="limit"
            @current-change="OrderList()"
            :current-page.sync="page"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 暂无订单 -->
      <div class="no-order" v-else>
        <div class="n-img">
          <img src="../../../assets/images/no-order.png" alt="" />
        </div>
        <p>亲，暂无订单信息哟~</p>
      </div>
      <!-- 取消订单弹出对话框 -->
      <el-dialog
        width="30%"
        title="选择取消订单的原因"
        :visible.sync="cancelDialogVisible"
        :before-close="Cancellation"
        class="d-dialog"
      >
        <div class="d-content">
          <div class="d-info">
            <i class="fa fa-comment-o"></i>
            &nbsp;
            <span
              >订单取消成功后将无法恢复；拆单后取消订单，其他子单也将一并取消</span
            >
          </div>
          <div class="d-list">
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 1 ? 'l-current' : ''"
                @click="reasonstatus = 1"
              >
                地址信息填写错误
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 2 ? 'l-current' : ''"
                @click="reasonstatus = 2"
              >
                不想要了
              </div>
            </div>
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 3 ? 'l-current' : ''"
                @click="reasonstatus = 3"
              >
                商品错选/多选
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 4 ? 'l-current' : ''"
                @click="reasonstatus = 4"
              >
                商品无货
              </div>
            </div>
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 5 ? 'l-current' : ''"
                @click="reasonstatus = 5"
              >
                商品降价
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 6 ? 'l-current' : ''"
                @click="reasonstatus = 6"
              >
                没用/少用/错用优惠
              </div>
            </div>
          </div>
          <div class="d-btn">
            <div class="b-item" @click="Cancellation()">再想想</div>
            <div class="b-item b-submit b-select" @click="CancellationOrder()">
              提交
            </div>
          </div>
        </div>
      </el-dialog>
      <!--待收货状态取消订单弹出对话框 -->
      <el-dialog
        width="30%"
        title="选择取消订单的原因"
        :visible.sync="cancelOrderDialog"
        :before-close="cancelOrderClose"
        class="d-dialog"
      >
        <div class="d-content">
          <div class="d-info">
            <i class="fa fa-comment-o"></i>
            &nbsp;
            <span>订单取消成功后，支付金额经系统审核，将原路退回！</span>
          </div>
          <div class="d-list">
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 1 ? 'l-current' : ''"
                @click="reasonstatus = 1"
              >
                地址信息填写错误
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 2 ? 'l-current' : ''"
                @click="reasonstatus = 2"
              >
                不想要了
              </div>
            </div>
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 3 ? 'l-current' : ''"
                @click="reasonstatus = 3"
              >
                商品错选/多选
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 4 ? 'l-current' : ''"
                @click="reasonstatus = 4"
              >
                商品无货
              </div>
            </div>
            <div class="l-content">
              <div
                class="l-item"
                :class="reasonstatus === 5 ? 'l-current' : ''"
                @click="reasonstatus = 5"
              >
                商品降价
              </div>
              <div
                class="l-item"
                :class="reasonstatus === 6 ? 'l-current' : ''"
                @click="reasonstatus = 6"
              >
                没用/少用/错用优惠
              </div>
            </div>
          </div>
          <div class="d-btn">
            <div class="b-item" @click="CancellationPay()">再想想</div>
            <div
              class="b-item b-submit b-select"
              @click="CancellationPayOrder()"
            >
              提交
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { OrderListPost, OrderCancellation,PayOrderCancellation,ReceivingOrder } from '@/api/order'
  export default {
    name: 'orderList',
    data() {
      return {
        page: 1,
        limit:10,
        count:0,
        name: '',
        status: 0,
        loading: false,
        list: [],
        reasonsid: 0,
        reasonstatus: 1,
        cancelDialogVisible: false, //未支付 取消订单弹出框
        cancelOrderDialog:false,    //已支付 取消订单弹出框
      }
    },
    methods: {
      Cancellation() { // 未支付，取消订单弹窗按钮关闭
        this.reasonsid = 0;
        this.reasonstatus = 1;
        this.cancelDialogVisible = false;
      },
      async CancellationOrder() { // 未支付，取消订单数据提交
        const { data: res } = await OrderCancellation({ id: this.reasonsid, reasonstatus: this.reasonstatus, page: this.page, name: this.name, status: this.status });
        if (res.message == 'ok') {
          this.list  = res.result.list;
          this.count = res.result.count;
          this.Cancellation();
        }
      },
      async CancellationPayOrder(){ // 已支付，取消订单数据提交
        const { data: res } = await PayOrderCancellation({ id: this.reasonsid, reasonstatus: this.reasonstatus, page: this.page, name: this.name, status: this.status });
        if (res.message == 'ok') {
          this.list  = res.result.list;
          this.count = res.result.count;
          this.CancellationPay();
        }
      },
      CancellationPay(){ // 已支付，取消订单弹窗按钮关闭
        this.reasonsid = 0;
        this.reasonstatus = 1;
        this.cancelOrderDialog = false;
      },
      ClickStatus(status) {
        this.page = 1;
        this.status = status;
        this.OrderList();
      },
      Topay(id) { // 跳转至支付页面
        let routeUrl = this.$router.resolve({ path: "/home/payment", query: { id: id } });
        window.open(routeUrl.href, '_blank');
      },
      async OrderList() { // 订单列表数据请求
        this.loading = true;
        const { data: res } = await OrderListPost({ page: this.page, name: this.name, status: this.status});
        if (res.message == 'ok') {
          this.list  = res.result.list
          this.count = res.result.count
        }
        this.loading = false;
      },
      confirmGoods(id) { //  确认收货弹出框
        this.$confirm("您确定收到货物且质量符合交易双方的约定吗?如果确认无误后,请点击下面的[确定]完成确认收货的操作。提示:请收到货物后执行此操作,否则可能会钱货两空。", '确认收货', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.Receiving(id);
        }).catch(() => {this.$message({type:'info',message: '已取消操作'});});
      },
      async Receiving(id){ // 确认收货数据提交
        this.loading = true;
        const { data: res } = await ReceivingOrder({id:id,page: this.page, name: this.name, status: this.status});
        this.loading = false;
        if (res.message == 'ok') {
          this.$message({type: 'success',message: '确认收货操作成功!'});
          this.list  = res.result.list
          this.count = res.result.count
        }else{
          this.$message({type: 'success',message: '确认收货操作失败!'});
        }
      },
      cancelOrderClose(){// 待收货取消订单弹出框 
        this.cancelOrderDialog=false
      }
    },
    created() {
      this.OrderList(0);
    }
  }
</script>

<style lang="less" scoped>
.order-list {
  width: 1000px;
  position: relative;
  min-height: 730px;

  .o-container {
    padding: 20px;

    // border: 1px solid #e1251b;
    // min-height: 1000px;
    .o-hd {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;

      .h-title {
        height: 40px;
        display: flex;
        justify-content: space-between;

        .t-item {
          height: 40px;
          text-align: center;
          line-height: 40px;
          margin-right: 20px;
          font-size: 14px;
          cursor: pointer;
        }

        .t-current {
          border-bottom: 2px solid #e1251b;
        }
      }

      .h-search {
        display: flex;
      }
    }

    .o-bd {
      .b-content {
        margin: 10px 0px;
        //   background: #f5f5f5;
        //   border: 1px solid #e1251b;

        .b-title {
          display: flex;
          height: 34px;
          text-align: center;
          line-height: 34px;
          background: #f5f5f5;

          .b-first {
            width: 520px;
            height: 34px;
          }

          .b-item {
            width: 120px;
            height: 34px;
          }
        }

        .b-list {
          box-sizing: border-box;
          border: 1px solid #ddd;
          margin-top: 15px;
          margin-bottom: 25px;

          .l-hd {
            background: #f5f5f5;
            padding: 0px 20px;
            box-sizing: border-box;

            .h-content {
              height: 30px;
              display: flex;
              line-height: 30px;
              color: #999;

              .h-order {
                margin: 0px 105px;
              }
            }
          }

          .l-main {
            height: 90px;
            //   border: 1px solid #e1251b;
            box-sizing: border-box;
            display: flex;
            min-height: 100px;
            height: auto;

            .m-goods {
              width: 560px;
              border-right: 1px solid #ddd;

              .g-list {
                box-sizing: border-box;
                height: 80px;
                border-bottom: 1px solid #ddd;
                display: flex;
                padding: 10px 20px;

                .g-list:last-child {
                  border-bottom: 1px solid red;
                }

                .g-img {
                  width: 60px;
                  height: 60px;

                  img {
                    width: 60px;
                    height: 60px;
                  }
                }

                .g-name {
                  margin: 0 60px 0px 10px;

                  a {
                    width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    color: #000;
                    line-height: 18px;
                    font-size: 12px;
                  }

                  a:hover {
                    color: #e1251b;
                  }
                }

                .g-num {
                  color: #999;
                }
              }

              .g-more {
                font-size: 14px;
                height: 20px;
                text-align: center;
                line-height: 20px;

                a {
                  color: #333;
                }

                a:hover {
                  color: #e1251b;
                }
              }
            }

            .m-item {
              width: 100px;
              border-right: 1px solid #ddd;
            }

            .g-info {
              display: flex;
              text-align: center;

              .m-name {
                box-sizing: border-box;
                padding-top: 10px;
              }

              .m-money {
                color: #999;
                padding: 10px 10px 0px;

                //   display: flex;
                //   flex-direction: column;
                //   justify-content: center;
                //   padding: 0px 10px;
                .m-txt {
                  border-bottom: 1px solid #ddd;
                  margin-bottom: 10px;
                  padding-bottom: 10px;
                }
              }

              .m-status {
                color: #999;
                padding-top: 10px;
                display: flex;
                flex-direction: column;
                padding: 10px 10px 0px;
                color: #d69977;
                .s-txt {
                  margin-top: 10px;
                  padding-top: 10px;
                  border-top: 1px solid #ddd;
                }
              }

              .m-opt {
                border-right: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;

                .o-btn {
                  width: 80px;
                  height: 30px;
                  background: #f5f5f5;
                  border: 1px solid #ddd;
                  line-height: 30px;
                  cursor: pointer;

                  i {
                    color: #999;
                  }

                  &:hover {
                    border: 1px solid #e1251b;
                    color: #e1251b;

                    i {
                      color: #e1251b;
                    }

                    span {
                      color: #e1251b;
                    }
                  }
                }

                .o-btp {
                  width: 80px;
                  height: 30px;
                  background: red;
                  border: 1px solid #fff;
                  line-height: 30px;
                  color: #fff;
                  cursor: pointer;

                  i {
                    color: #fff;
                  }

                  span {
                    color: #fff;
                  }

                  &:hover {
                    border: 1px solid #eee;
                    color: #eee;

                    i {
                      color: #eee;
                    }

                    span {
                      color: #eee;
                    }
                  }
                }

                .o-cancel {
                  color: #666;
                  cursor: pointer;
                  margin-top: 10px;

                  &:hover {
                    color: #e1251b;
                  }
                }
              }
            }
          }
        }
      }
      .o-footer {
        float: right;
        margin-bottom: 20px;
      }
    }
  }

  .no-order {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    .n-img {
      img {
        width: 274px;
        height: 174px;
      }
    }

    p {
      font-size: 14px;
      color: #999;
      margin: 20px;
    }
  }

  .d-dialog {
    /deep/ .el-dialog__header {
      background: #f3f3f3;
      padding: 0px;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;

      .el-dialog__title {
        font-size: 14px;
        color: #666;
      }

      .el-dialog__headerbtn {
        top: 15px;
      }
    }

    /deep/ .el-dialog__body {
      padding: 0px;
      font-size: 12px;
    }

    .d-content {
      padding-bottom: 20px;

      .d-info {
        padding: 10px 20px;
        background: #fffbdb;
      }

      .d-list {
        padding: 10px 20px;

        .l-content {
          margin-top: 10px;
          display: flex;
          justify-content: center;

          .l-item {
            width: 220px;
            line-height: 40px;
            border: 2px solid #ddd;
            text-align: center;
            margin-right: 20px;
            cursor: pointer;

            &:hover {
              border: 2px solid #e1251b;
            }
          }

          .l-current {
            border: 2px solid #e1251b;
            background: url("../../../assets/images/opt.png") no-repeat;
            background-position: right bottom;
          }
        }
      }

      .d-btn {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .b-item {
          width: 90px;
          line-height: 40px;
          border: 1px solid #ddd;
          text-align: center;
          margin-right: 20px;
          color: #333;
          font-size: 14px;
          border-radius: 3px;
          cursor: pointer;
        }

        .b-submit {
          background: #f7f7f7;
          color: #ccc;
        }

        .b-select {
          background: #e1251b;
          color: #fff;
        }
      }
    }
  }
}
</style>